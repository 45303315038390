import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Link } from 'gatsby'
import Img from 'gatsby-image'

import { makeStyles } from '@material-ui/core/styles'
import Layout from '../components/Layout'
import SEO from '../components/Seo'
import Typography from '../components/Typography'

const useStyles = makeStyles({
  container: {
    minHeight: 'calc(90vh - 220px)',
    paddingTop: '20px',
    marginBottom: '30px'
  },
  image: {
    height: '50vh',
    filter: 'grayscale(1)',
    marginBottom: '20px'
  },
  link: {
    fontWeight: 600,
    textDecoration: 'none',
    color: 'inherit'
  }
})

const NotFoundPage = () => {
  const classes = useStyles()
  const notFound = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "notFound/image1.jpg" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 900) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="404: Not found" />
      <div className={classes.container}>
        <Img fluid={notFound.placeholderImage.childImageSharp.fluid} className={classes.image} />
        <Typography variant="h2">You just hit a route that doesn&#39;t exist... Go <Link to="/" className={classes.link}>Home</Link></Typography>
      </div>
    </Layout>
  )
}

export default NotFoundPage
